export default defineNuxtPlugin(() => {
    const getFacebookObject = () => window.FB

    const load = async () => {
        return new Promise((resolve, reject) => {
            if (window.FB) {
                return resolve(window.FB)
            }

            // const src = `https://connect.facebook.net/es_MX/sdk.js`
            const src = `https://connect.facebook.net/en_US/sdk/debug.js`
            const script = document.createElement('script')
            script.id = 'facebook-jssdk'
            script.src = src
            script.async = true
            script.addEventListener('load', () => resolve(window.FB), false)
            script.addEventListener('error', () => reject(`Error loading Facebook JS SDK from ${src}`), false)

            const sibling = document.getElementsByTagName('script')[0]
            sibling.parentNode?.insertBefore(script, sibling)
        })
    }

    const init = (): void => {
        const FB = getFacebookObject()

        FB.init({
            appId: '2073945102946613',
            xfbml: true,
            version: 'v18.0',
        })
    }

    return {
        provide: {
            facebookAuth: {
                init,
                load,
                getFacebookObject,
            },
        },
    }
})

interface FacebookAuthPlugin {
    $facebookAuth: {
        init: () => void
        load: () => Promise<fb.StatusResponse>
        getFacebookObject: () => facebook.FacebookStatic
    }
}

declare module '#app' {
    interface NuxtApp extends FacebookAuthPlugin {}
}

declare module 'nuxt/dist/app/nuxt' {
    interface NuxtApp extends FacebookAuthPlugin {}
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties extends FacebookAuthPlugin {}
}
