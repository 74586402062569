export default defineNuxtPlugin(() => {
    const theAssets = {
        logo: {
            default: '/brand/main-logo.png',
            mini: '/brand/main-logo-mini.svg',
            hurtadodealspowered: '/brand/hurtado-deals-powered.svg',
            footer: '/brand/logo-footer.svg',
        },
        images: {
            404: '/images/404.svg',
            aboutus: '/images/sobre-nosotros.png',
            bgus: '/images/bg-nosotros.svg',
        },
        icons: {
            facebook: '/icons/facebook.svg',
            google: '/icons/google.svg',
        },
        collections: {
            elmilenio: '/images/collections/el-milenio-visto-por-el-arte.svg',
            lapoesia: '/images/collections/la-poesia-vista-por-el-arte.svg',
            elmural: '/images/collections/el-mural-del-milenio.svg',
            lunasol: '/images/collections/luna-sol-dualidad.svg',
            elamor: '/images/collections/el-amor-visto-por-el-arte.svg',
            elarte: '/images/collections/el-arte-del-vino.svg',
            eltiempo: '/images/collections/el-tiempo.svg',
            cancion: '/images/collections/el-arte-de-la-cancion.svg',
            white: {
                elmilenio: '/images/collections/el-milenio-visto-por-el-arte-white.svg',
                lapoesia: '/images/collections/la-poesia-vista-por-el-arte-white.svg',
                elmural: '/images/collections/el-mural-del-milenio-white.svg',
                lunasol: '/images/collections/luna-sol-dualidad-white.svg',
                elamor: '/images/collections/el-amor-visto-por-el-arte-white.svg',
                elarte: '/images/collections/el-arte-del-vino-white.svg',
                eltiempo: '/images/collections/el-tiempo-white.svg',
                cancion: '/images/collections/el-arte-de-la-cancion-white.svg',
            },
        },
        gray: {
            home: '/icons/home.svg',
            quote: '/icons/quote-gray.svg',
        },
        black: {
            user: '/icons/default-user-image.svg',
            plane: '/icons/send-plane-line.svg',
            menu: '/icons/menu-black.svg',
            search: '/icons/search-black.svg',
            collections: '/icons/collections-black.svg',
            paint: '/icons/paint-black.svg',
            date: '/icons/date-black.svg',
            dead: '/icons/dead-black.svg',
        },
        green: {
            email: '/icons/email-green.svg',
        },
    }

    return {
        provide: {
            assets: theAssets,
        },
    }
})
