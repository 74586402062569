export default defineNuxtPlugin(() => {
    const signin = () => {
        // Google's OAuth 2.0 endpoint for requesting an access token
        var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

        // Create <form> element to submit parameters to OAuth 2.0 endpoint.
        var form = document.createElement('form')
        form.setAttribute('method', 'GET') // Send as a GET request.
        form.setAttribute('action', oauth2Endpoint)

        // Parameters to pass to OAuth 2.0 endpoint.
        var params = {
            client_id: '582142614379-2vqci6no08kcdt0qjc13tr78slj3m0gr.apps.googleusercontent.com',
            redirect_uri: `${location.origin}/google-auth-callback`,
            response_type: 'token',
            scope: 'openid profile email',
            include_granted_scopes: 'true',
            state: 'pass-through value',
        }

        // Add form parameters as hidden input values.
        for (var p in params) {
            var input = document.createElement('input')
            input.setAttribute('type', 'hidden')
            input.setAttribute('name', p)
            input.setAttribute('value', params[p as keyof typeof params])
            form.appendChild(input)
        }

        // Add form to page and submit it to open the OAuth 2.0 endpoint.
        document.body.appendChild(form)
        form.submit()
    }

    return {
        provide: {
            googleAuth: {
                signin,
            },
        },
    }
})

interface GoogleAuthPlugin {
    $googleAuth: {
        signin: () => void
    }
}

declare module '#app' {
    interface NuxtApp extends GoogleAuthPlugin {}
}

declare module 'nuxt/dist/app/nuxt' {
    interface NuxtApp extends GoogleAuthPlugin {}
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties extends GoogleAuthPlugin {}
}
