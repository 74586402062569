import constants from '~/config/constants'

import { useAuth } from '~/stores/authentication'

export default defineNuxtRouteMiddleware(async () => {
    if (process.server) {
        const tokenCookie = useCookie(constants.authToken)

        if (tokenCookie.value) {
            const AuthStore = useAuth()

            const { USER_PROFILE } = useProfileService()

            try {
                const profile = await USER_PROFILE('Bearer ' + tokenCookie.value)

                if (profile) {
                    AuthStore.setAuthData({
                        profile,
                        token: tokenCookie.value,
                        status: true,
                    })
                } else {
                    tokenCookie.value = null
                }
            } catch (e) {
                tokenCookie.value = null
            }
        }
    }
})
