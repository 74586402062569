import { default as _91slug_934gMPb3lpTvMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/artistas/[slug].vue?macro=true";
import { default as indexu5KWl32FuoMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/artistas/index.vue?macro=true";
import { default as aviso_45de_45privacidad4b8k4wAmSHMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/aviso-de-privacidad.vue?macro=true";
import { default as indexYnRfAfX1DSMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/exhibiciones/index.vue?macro=true";
import { default as google_45auth_45callbackP4pemYzxMeMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/google-auth-callback.vue?macro=true";
import { default as indexFrCjSWww6sMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/index.vue?macro=true";
import { default as loginiKhg7Q2xHoMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/login.vue?macro=true";
import { default as nosotroseFEt7aMJfRMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/nosotros.vue?macro=true";
import { default as indexiOZpntWiNdMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/perfil/index.vue?macro=true";
import { default as indexuE2nIXRQf0Meta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/proyectos-especiales/index.vue?macro=true";
import { default as recuperarA9wHBmbEZyMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/recuperar.vue?macro=true";
import { default as registroGDvH2ZFt9WMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/registro.vue?macro=true";
import { default as restablecerYHcy2ZX58oMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/restablecer.vue?macro=true";
import { default as searchmVHd4V5uabMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/search.vue?macro=true";
import { default as index7crSyFDCq7Meta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/series/index.vue?macro=true";
import { default as verificacionaUlzwXRNHYMeta } from "/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/verificacion.vue?macro=true";
import { default as PostDetailsHf9SqIvMR0Meta } from "~/components/views/PostDetails.vue?macro=true";
export default [
  {
    name: _91slug_934gMPb3lpTvMeta?.name ?? "artistas-slug",
    path: _91slug_934gMPb3lpTvMeta?.path ?? "/artistas/:slug()",
    meta: _91slug_934gMPb3lpTvMeta || {},
    alias: _91slug_934gMPb3lpTvMeta?.alias || [],
    redirect: _91slug_934gMPb3lpTvMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/artistas/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexu5KWl32FuoMeta?.name ?? "artistas",
    path: indexu5KWl32FuoMeta?.path ?? "/artistas",
    meta: indexu5KWl32FuoMeta || {},
    alias: indexu5KWl32FuoMeta?.alias || [],
    redirect: indexu5KWl32FuoMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/artistas/index.vue").then(m => m.default || m)
  },
  {
    name: aviso_45de_45privacidad4b8k4wAmSHMeta?.name ?? "aviso-de-privacidad",
    path: aviso_45de_45privacidad4b8k4wAmSHMeta?.path ?? "/aviso-de-privacidad",
    meta: aviso_45de_45privacidad4b8k4wAmSHMeta || {},
    alias: aviso_45de_45privacidad4b8k4wAmSHMeta?.alias || [],
    redirect: aviso_45de_45privacidad4b8k4wAmSHMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/aviso-de-privacidad.vue").then(m => m.default || m)
  },
  {
    name: indexYnRfAfX1DSMeta?.name ?? "exhibiciones",
    path: indexYnRfAfX1DSMeta?.path ?? "/exhibiciones",
    meta: indexYnRfAfX1DSMeta || {},
    alias: indexYnRfAfX1DSMeta?.alias || [],
    redirect: indexYnRfAfX1DSMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/exhibiciones/index.vue").then(m => m.default || m)
  },
  {
    name: google_45auth_45callbackP4pemYzxMeMeta?.name ?? "google-auth-callback",
    path: google_45auth_45callbackP4pemYzxMeMeta?.path ?? "/google-auth-callback",
    meta: google_45auth_45callbackP4pemYzxMeMeta || {},
    alias: google_45auth_45callbackP4pemYzxMeMeta?.alias || [],
    redirect: google_45auth_45callbackP4pemYzxMeMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/google-auth-callback.vue").then(m => m.default || m)
  },
  {
    name: indexFrCjSWww6sMeta?.name ?? "index",
    path: indexFrCjSWww6sMeta?.path ?? "/",
    meta: indexFrCjSWww6sMeta || {},
    alias: indexFrCjSWww6sMeta?.alias || [],
    redirect: indexFrCjSWww6sMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginiKhg7Q2xHoMeta?.name ?? "login",
    path: loginiKhg7Q2xHoMeta?.path ?? "/login",
    meta: loginiKhg7Q2xHoMeta || {},
    alias: loginiKhg7Q2xHoMeta?.alias || [],
    redirect: loginiKhg7Q2xHoMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: nosotroseFEt7aMJfRMeta?.name ?? "nosotros",
    path: nosotroseFEt7aMJfRMeta?.path ?? "/nosotros",
    meta: nosotroseFEt7aMJfRMeta || {},
    alias: nosotroseFEt7aMJfRMeta?.alias || [],
    redirect: nosotroseFEt7aMJfRMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/nosotros.vue").then(m => m.default || m)
  },
  {
    name: indexiOZpntWiNdMeta?.name ?? "perfil",
    path: indexiOZpntWiNdMeta?.path ?? "/perfil",
    meta: indexiOZpntWiNdMeta || {},
    alias: indexiOZpntWiNdMeta?.alias || [],
    redirect: indexiOZpntWiNdMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: indexuE2nIXRQf0Meta?.name ?? "proyectos-especiales",
    path: indexuE2nIXRQf0Meta?.path ?? "/proyectos-especiales",
    meta: indexuE2nIXRQf0Meta || {},
    alias: indexuE2nIXRQf0Meta?.alias || [],
    redirect: indexuE2nIXRQf0Meta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/proyectos-especiales/index.vue").then(m => m.default || m)
  },
  {
    name: recuperarA9wHBmbEZyMeta?.name ?? "recuperar",
    path: recuperarA9wHBmbEZyMeta?.path ?? "/recuperar",
    meta: recuperarA9wHBmbEZyMeta || {},
    alias: recuperarA9wHBmbEZyMeta?.alias || [],
    redirect: recuperarA9wHBmbEZyMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/recuperar.vue").then(m => m.default || m)
  },
  {
    name: registroGDvH2ZFt9WMeta?.name ?? "registro",
    path: registroGDvH2ZFt9WMeta?.path ?? "/registro",
    meta: registroGDvH2ZFt9WMeta || {},
    alias: registroGDvH2ZFt9WMeta?.alias || [],
    redirect: registroGDvH2ZFt9WMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/registro.vue").then(m => m.default || m)
  },
  {
    name: restablecerYHcy2ZX58oMeta?.name ?? "restablecer",
    path: restablecerYHcy2ZX58oMeta?.path ?? "/restablecer",
    meta: restablecerYHcy2ZX58oMeta || {},
    alias: restablecerYHcy2ZX58oMeta?.alias || [],
    redirect: restablecerYHcy2ZX58oMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/restablecer.vue").then(m => m.default || m)
  },
  {
    name: searchmVHd4V5uabMeta?.name ?? "search",
    path: searchmVHd4V5uabMeta?.path ?? "/search",
    meta: searchmVHd4V5uabMeta || {},
    alias: searchmVHd4V5uabMeta?.alias || [],
    redirect: searchmVHd4V5uabMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: index7crSyFDCq7Meta?.name ?? "series",
    path: index7crSyFDCq7Meta?.path ?? "/series",
    meta: index7crSyFDCq7Meta || {},
    alias: index7crSyFDCq7Meta?.alias || [],
    redirect: index7crSyFDCq7Meta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: verificacionaUlzwXRNHYMeta?.name ?? "verificacion",
    path: verificacionaUlzwXRNHYMeta?.path ?? "/verificacion",
    meta: verificacionaUlzwXRNHYMeta || {},
    alias: verificacionaUlzwXRNHYMeta?.alias || [],
    redirect: verificacionaUlzwXRNHYMeta?.redirect || undefined,
    component: () => import("/home/cleavr/www.coleccionmilenioarte.com/releases/20240902114125408/src/pages/verificacion.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "series-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/series/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "exhibiciones-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/exhibiciones/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  },
  {
    name: PostDetailsHf9SqIvMR0Meta?.name ?? "proyectos-especiales-slug",
    path: PostDetailsHf9SqIvMR0Meta?.path ?? "/proyectos-especiales/:slug",
    meta: PostDetailsHf9SqIvMR0Meta || {},
    alias: PostDetailsHf9SqIvMR0Meta?.alias || [],
    redirect: PostDetailsHf9SqIvMR0Meta?.redirect || undefined,
    component: () => import("~/components/views/PostDetails.vue").then(m => m.default || m)
  }
]