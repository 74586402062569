import { Models } from '~/types/models'

export const useAuth = defineStore('auth', {
    state: () => ({
        isAuth: ref(false),
        userProfile: ref<null | Models.UserResource>(null),
        token: ref<null | string>(null),
    }),
    actions: {
        setAuthStatus(status: boolean) {
            this.isAuth = status
        },
        setUserProfile(profile: Models.UserResource | null) {
            this.userProfile = profile
        },
        setToken(token?: string) {
            this.token = token ? `Bearer ${token}` : null
        },
        setAuthData(data: { status?: boolean; profile?: Models.UserResource | null; token?: string | null }) {
            if (data.status !== undefined) {
                this.isAuth = data.status
            }
            if (data.token !== undefined) {
                this.token = data.token ? `Bearer ${data.token}` : null
            }
            if (data.profile !== undefined) {
                this.userProfile = data.profile
            }
        },
    },
})
