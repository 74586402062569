export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:image","content":"/brand/main-logo.png"},{"name":"twitter:image","content":"/brand/main-logo.png"}],"link":[],"style":[],"script":[{"key":"gtm-script","type":"text/javascript","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n                        })(window,document,'script','dataLayer','GTM-WNC53SQ6');"}],"noscript":[{"innerHTML":"<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-WNC53SQ6'\n                    height='0\" width='0' style='display:none;visibility:hidden'></iframe>"}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null